import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import { useScreen } from './ScreenContext';
import '../stylesheets/InfoCotizacion.css';
import PopUpCotizacion from './PopUpCotizacion'

function MostrarInfoCotizacion({ nombreSeguro, idSeguro, info, coberturas, 
                                handleVolverAtras}) {

  const [showPopUp, setShowPopUp] = useState(false); //controla visibilidad del PopUp

  const clickPopUp = () => {
    setShowPopUp(true); // Mostrar el PopUp al hacer clic en el botón
  };
  
  const handleClosePopUp = () => {
    setShowPopUp(false); // Ocultar el PopUp al hacer clic en x
  };
  const { currentScreen, setScreen } = useScreen(); 
  const navigate = useNavigate();

  const MiCuenta = () => {
    setScreen('MiCuenta');
    navigate('/micuenta');
    window.scrollTo(0, 0); 
  };

  if (!info || info.length === 0) {
    return (
      <div>
        <p>No se encontraron cotizaciones.</p>
        <p>Debe ingresar un valor válido</p>
        <button className="btn-back" onClick={handleVolverAtras}>Volver atrás</button>
      </div>
    );
  }


  console.log('INFO: ', info);
  // console.log(info[0].Cotizacion.CotCod);
  const CotCod = info && info.length > 0 && info[0].Cotizacion && info[0].Cotizacion.CotCod;
  // Busco Id de cotización para url
  // console.log(CotCod);
  

  return (
    <div className="contenedor-principal-cot">
        <div>
          <div className="titulo-info-cotizacion">
            {nombreSeguro}
          </div>
          {/* Inserto el logo */}
          <div className="contenedor-logo-seguro">
            <img 
              src={`data:image/png;base64,${info[0].Cotizacion.AseLogo}`} 
              alt='Imagen seguro' 
              className="logo-seguro"/>
          </div>
          {/* Validar que haya información sobre la cotización */}
          <div>
            <span className="cuota-mensual">CUOTA MENSUAL</span><br></br>
            {info && info.length > 0 ? (
              <span className="precio-cuota-mensual">
                $ {info[0].Cotizacion.Precio}
              </span>
            ) : (
              <span className="precio-cuota-mensual">No hay información disponible</span>
            )}
          </div>
          {/* Validar que haya valor asegurado sobre la cotización */}
         <div>
            {info && info.length > 0 && ((parseInt(idSeguro)) == 8 ) ? (
              <>
              <span className="cuota-mensual">VALOR ASEGURADO</span><br></br>
              <span className="precio-cuota-mensual">
                $ {info[0].Cotizacion.CotAseValAse}
              </span>
              </>
            ) : null}
            </div>
          <div>
            {/* Validar que haya coberturas */}
            {coberturas && coberturas.length > 0 ? (
              coberturas.map((cobertura, index) => (
                <div className="contenedor-tipo-cobertura">
                  <div className="titulo-tipo-cobertura">
                    { cobertura.CobDes !== "---" ? cobertura.CobDes : null }
                  </div>
                  <div className={cobertura.ProSetCobVal != 'COBERTURAS' ?
                    "valor-tipo-cobertura" : "titulo-cobertura"}>
                    {cobertura.ProSetCobVal}
                  </div>
                </div>
              ))
            ) : (
              <p>No hay coberturas disponibles.</p>
            )}
          </div>
        </div>

      <button className="btn-seleccionar" onClick={clickPopUp}>SELECCIONAR</button>
      <button className="btn-back" onClick={handleVolverAtras}>CANCELAR</button>
      {showPopUp && (
        <PopUpCotizacion handleClose={handleClosePopUp} codCot={CotCod} />
      )}
    </div>
  );
}

export default MostrarInfoCotizacion;
