import React from 'react';
import '../stylesheets/ListaTipoPlan.css';
import TipoPlan from './TipoPlan';
import TextInput from './InputTexto';
import BotonFlecha from './BotonFlecha';
import { useState, useEffect } from 'react';
import { useScreen } from './ScreenContext';
import BotonOpcInstMusicales from './BotonOpcInstMusicales';
import BtnFlechaInstrumentosMusicales from './BotonFlechaInstMusicales';
import Dropdown from './Dropdown';
import useObtenerPlanes from '../utils/ServiceGetPlanes';
import useObtenerCampos from '../utils/ServiceGetCampos';

function ListaTipoPlan( {idTipoSeguro, EmpCod, ProTipCod, 
  TabCod, TabCodCel, AseCod, handleClick, setBodyCotizacion, setCampoLongSDT} ){

    //console.log('ListaTipoPlan.js' , 'IdSeguro: ', idTipoSeguro);
    const planes = useObtenerPlanes(EmpCod, ProTipCod, TabCod, TabCodCel, AseCod); // Hook
    const camposTodoRiesgo = useObtenerCampos(ProTipCod);

    const [valorAsegurado, setValorAsegurado] = useState('');
    const [valorTotalMoviles, setValorTotalMoviles] = useState('');
    const [valorTotalFijos, setValorTotalFijos] = useState('');

    // arreglos para los campos de todo riesgo electrónicos e inst musicales
    const condicionIVA = [];
    let placeholderValorAsegurado, placeholderValorTotalMoviles, placeholderValorTotalFijos = null;

    // arreglos para el campo LongSDT del body todo riesgo electrónicos
    // Recordar son 3, uno para cada input
    const [LongSDT_CondIVA, setLongSDT_CondIVA] = useState();

    // saber qué botón se selecciona en instrumentos musicales
    const [selectedBtn, setSelectedBtn] = useState(null);

    // parámetros para crear la cotización
    const [EmpCodPlan, setEmpCodPlan] = useState(null);
    const [ProTipCodPlan, setProTipCodPlan] = useState(null);
    const [TabCodPlan, setTabCodPlan] = useState(null);
    const [CodCelPlan, setCodCelPlan] = useState(null);

    const handleButtonClick = (btnId, EmpCodPlan, ProTipCodPlan, 
                              TabCodPlan, CodCelPlan) => {
      setSelectedBtn(btnId);
      setEmpCodPlan(EmpCodPlan);
      setProTipCodPlan(ProTipCodPlan);
      setTabCodPlan(TabCodPlan);
      setCodCelPlan(CodCelPlan);
      /*console.log(EmpCodPlan, ' - ', ProTipCodPlan, ' - ', 
                   TabCodPlan, ' - ', CodCelPlan); */
    };
    
    const {currentScreen} = useScreen();
    const handleButtonClickAsesoria = (plan) => {
      if (currentScreen === 'Asesoria') {
      } else {
        handleClick();
      }
    };

    const [isDesktop, setIsDesktop] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        setIsDesktop(window.innerWidth > 768); // Definir el ancho mínimo para considerar como escritorio
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Llamar a la función inicialmente para establecer el estado correcto
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    /*
      Obtener campos para todo riesgo electrónico e instrumentos musicales. 
      Acá se obtienen los inputs. Para todo riesgo electrónico: condición frente al iva, valor de equipos
      fijo y valor de equipos móviles. 
      Para instrumentos musicales solo valor asegurado. 
      Cada input arma un string array con los campos CamDinCod, CamDinVal, CamDinObl,
      isFile, y luego este será el valor del campo LongSDT.
      Entonces para todo riesgo electrónicos tendremos 3 {"clave": "valor"} pues son
      3 inputs. Para instrumentos musciales tendremos 1.
    */

    useEffect(() =>{
      let LongSDT_IVA = null;
      for (let i=0; i < camposTodoRiesgo.length; i++){
        if (camposTodoRiesgo[i].CamDinDes == 'Condición IVA'){
          LongSDT_IVA = 
            {
              CamDinCod: camposTodoRiesgo[i].CamDinCod,
              IteDinCod: camposTodoRiesgo[i].IteDinCod,
              CamDinObl: true,
              isFile: false
            }
        break;
        }
      }
      setLongSDT_CondIVA(LongSDT_IVA);
    }, [camposTodoRiesgo]); 
    // Paso como dependencia el array camposTodoRiesgo para que este useEffect se ejecute
    // cuando el array cambie de estado, es decir, cuando se setea con los campos
    // que se obtienen en getCampos.

      
    camposTodoRiesgo.forEach(campo => {
      if (campo.CamDinDes == 'Condición IVA'){
        condicionIVA.push(campo);
      }
      else if (campo.CamDinDes == 'Valor total de equipos móviles'){
        placeholderValorTotalMoviles = campo.CamDinDes;
      }
      else if (campo.CamDinDes == 'Valor total de equipos fijos (Obligatorio)'){
        placeholderValorTotalFijos = campo.CamDinDes;
      }
      else  if (campo.CamDinDes == 'Valor Asegurado'){
        placeholderValorAsegurado = campo.CamDinDes;
      }
    });
    
    

  //console.log('PROVINCIAS: ', provincias);
  //console.log('CONDICIÓN IVA: ', condicionIVA);
  //console.log('OTRA ETIQUETA: ', otraEtiqueta);

  // Obtener el valor asegurado ingresado por el usuario
  const handleInputValorAsegurado = (event) => {
    setValorAsegurado(event.target.value);
    console.log('Valor asegurado: ', valorAsegurado);
  };

  const handleInputValorTotalMoviles = (event) => {
    setValorTotalMoviles(event.target.value);
    console.log('Valor total móviles: ', valorTotalMoviles);
  };

  const handleInputValorTotalFijos = (event) => {
    setValorTotalFijos(event.target.value);
    console.log('Valor total fijos: ', valorTotalFijos);
  };

  const handleCondicionIva = (condicionElegida) => {
    let condIVA = null;
    for (let i=0; i < condicionIVA.length; i++){
      if (condicionIVA[i].IteDinCod == condicionElegida){
        condIVA = condicionIVA[i];
      }
    }
    if (condIVA) {
      let LongSDT3 = {
          CamDinCod: condIVA.CamDinCod,
          IteDinCod: condIVA.IteDinCod,
          CamDinObl: true,
          isFile: false
      };
      setLongSDT_CondIVA(LongSDT3);
  } else {
      console.error('No se encontró un valor válido para condIVA');
  }
  };

  const labelCondicionIVA = condicionIVA.length > 0 ? condicionIVA[4].IteDinDes : '';



  // console logs para pruebas
  //console.log('Arreglo LongSDT_Provincia: ', LongSDT_Provincia);
  //console.log('Arreglo LongSDT_CondIVA: ', LongSDT_CondIVA);
  

  return (
    <div className={planes.length > 4 ? 'lista-tipo-plan-1' : 'lista-tipo-plan-2'}>

      {parseInt(idTipoSeguro) === 7 ? (
       // Lista de planes: TODO RIESGO ELECTRÓNICO
          <div className='contenendor-inputs-todo-riesgo-elec'>
            <Dropdown 
              options={condicionIVA}
              label={labelCondicionIVA}
              onChange={handleCondicionIva} 
            />

            <TextInput 
              value={valorTotalFijos}
              onChange={handleInputValorTotalFijos}
              placeholder={placeholderValorTotalFijos}
            />
            <p>Es obligatorio cotizar al menos un equipo fijo. Ejemplo: un monitor.</p>

            <TextInput 
              value={valorTotalMoviles}
              onChange={handleInputValorTotalMoviles}
              placeholder={placeholderValorTotalMoviles}
            />
            {isDesktop ? (
              <>
                <p>Si son varios equipos, ingrese la suma total (de fijos o móviles) correspondiente al</p>
                <p style={{ marginTop: '2%' }}>valor actual de cada bien.</p>
              </>
            ) : (
              <p style={{ marginTop: '2%' }}>Si son varios equipos, ingrese la suma total (de fijos o móviles) correspondiente al valor actual de cada bien.</p>
            )}

            {LongSDT_CondIVA && !(isNaN(valorTotalFijos)) 
            && valorTotalFijos !== '' && valorTotalFijos !== null && (
              <div className='contenedor-boton-flecha'>
                <BtnFlechaInstrumentosMusicales 
                  handleClick={handleClick}
                  EmpCod={EmpCod}
                  ProTipCod={ProTipCod}
                  TabCod={TabCod}
                  TabCodCel={TabCodCel}
                  valorTotalMoviles={valorTotalMoviles}
                  valorTotalFijos={valorTotalFijos}
                  condIVA={LongSDT_CondIVA}
                  setBodyCotizacion={setBodyCotizacion}
                  setCampoLongSDT={setCampoLongSDT}
                />
              </div>
            )}

          </div>
     ) : (null)}

    
      {parseInt(idTipoSeguro) === 8 ? (
        // Lista de planes: INSTRUMENTOS MUSICALES
        <div className='contenedor-instrumentos-musicales'>
          <div className='lista-tipo-plan-instrumentos'>
            {planes.map((plan) => (  // <- Agrega el paréntesis de apertura aquí
              <div key={plan.TabCodCel}>
                <BotonOpcInstMusicales 
                  handleClick={
                    () => handleButtonClick(plan.TabCodCel, 
                                            plan.EmpCod, 
                                            plan.ProTipCod,
                                            plan.TabCod, 
                                            plan.TabCodCel)}
                  texto={plan.ProSetDes}
                  isClicked={selectedBtn === plan.TabCodCel} 
                />
              </div>
            ))}
            </div>
        <div>              
          <TextInput
            value={valorAsegurado}
            onChange={handleInputValorAsegurado}
            placeholder={placeholderValorAsegurado}
          />
          
          {selectedBtn && !(isNaN(valorAsegurado)) && (
          <div className='contenedor-boton-flecha'>
            <BotonFlecha 
              idPlan="1"
              texto="seguro"
              handleClick={handleClick}
              setBodyCotizacion={setBodyCotizacion}
              EmpCod={EmpCodPlan}
              ProTipCod={ProTipCodPlan}
              TabCod={TabCodPlan}
              TabCodCel={CodCelPlan}
              CamDinCod='7'
              CamDinVal={valorAsegurado}
              CamDinObl={true}
              isFile={false}
              setCampoLongSDT={setCampoLongSDT}  
            />
          </div>
          )}

          </div>
        </div>
      ) : null}

      
      {parseInt(idTipoSeguro) !== 7 && parseInt(idTipoSeguro) !== 8 ? (
      // Lista de planes: INTEGRAL FOOD TRUCK, ALQUILERES TEMPORARIOS, BICICLETAS
      planes.map((plan) => (
            <div className='contenedor-lista-tipo-plan'>
              <div key={plan.TabCodCel} className={planes.length > 4 ? 'tipo-plan-item'
                                          : 'tipo-plan-item-2'}>
                <TipoPlan 
                  texto= {plan.ProSetDes}
                  handleClick={() => handleButtonClickAsesoria(plan)} 
                  setBodyCotizacion={setBodyCotizacion}
                  EmpCod={plan.EmpCod}
                  ProTipCod={plan.ProTipCod}
                  TabCod={plan.TabCod}
                  TabCodCel={plan.TabCodCel}         
                  />
              </div>
            </div>
          ))
      ) : (null)}

      
    </div>
  );

}

export default ListaTipoPlan;